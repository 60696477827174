import gql from 'graphql-tag';

export const GET_RESERVATION_CALENDAR_DATA = gql`
    query GetReservationCalendarData($month:Int,$year:Int,$reservationPersonID:ID){
        monthReservations(month:$month,year:$year,reservationPersonID:$reservationPersonID){
            date
            customerID
            customer{
                name
                surname
            }
        }
        monthBlockedDays(month:$month,year:$year,reservationPersonID:$reservationPersonID){
            date
        }
        allReservationPersons{
            reservationPersonID
            name
        }
    }
`;
export const GET_RESERVATION_DATA = gql`
    query GetReservationData{
        allReservationPersons{
            reservationPersonID
            name
        }
    }
`;

export const BLOCK_DAY = gql`
    mutation ReservationBlockDay($date:String,$reservationPersonID:ID){
        reservationBlockDay(date:$date,reservationPersonID:$reservationPersonID)
    }
`;

export const REMOVE_BLOCKED_DAY = gql`
    mutation RemoveBlockedDay($date:String,$isReservation:Boolean,$reservationPersonID:ID){
        removeBlockedDay(date:$date,isReservation:$isReservation,reservationPersonID:$reservationPersonID)
    }
`;