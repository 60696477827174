/*
    Hlavní horní lišta, ktaré má v sobě i levé mennu
*/
import React,{ Component } from 'react';
import { NavLink } from "react-router-dom";
import onClickOutside from "react-onclickoutside";
import { withRouter } from 'react-router-dom';
import logo from '../Public/Images/logo.png';
import menu from '../Public/Images/menu.svg';
import door from '../Public/Images/door.svg';
import calendar from '../Public/Images/calendar.svg';
import basket from '../Public/Images/basket.svg';
import user from '../Public/Images/user.svg';
import content from '../Public/Images/content.svg';
import doorLighter from '../Public/Images/door_lighter.svg';
import settings from '../Public/Images/cogwheel.svg';
import { ApolloConsumer} from 'react-apollo';
import { AuthContext } from '../Modules/Auth/Components/AuthProvider';
import Auth from '../Modules/Auth/Library/auth';

const INITIAL_STATE = {
	cl:"",
	showSubmenu:[
	  {menuID:1,show:false},
	  {menuID:2,show:false},
	  {menuID:3,show:false},
	  {menuID:4,show:false},
	  {menuID:5,show:false}
	]
}

class Header extends Component {

	static contextType = AuthContext;
	
	  constructor(props){
		  super(props);
		  
		  this.showSideBar   = this.showSideBar.bind(this);
		  this.hideSideBar   = this.hideSideBar.bind(this);
		  this.toogleSubmenu = this.toogleSubmenu.bind(this);
		  
		  this.logout      = this.logout.bind(this);
		  this.state = {...INITIAL_STATE};

		  this.authLib = new Auth();
		  
	  }
	  
	  showSideBar(e){
		  e.preventDefault();
		  if(this.state.cl){
			  this.setState({cl:""});
		  }else{
			  this.setState({cl:"open"});
		  }
	  }
	  hideSideBar(e){
		  this.setState({cl:""});
	  }
	  
	  logout(e,client){	  

		  e.preventDefault();
		  client.resetStore();

		  localStorage.removeItem("token");
		  localStorage.removeItem("loggedUser");

		  window.location.reload();

	  }
	  
	  toogleSubmenu(e,menu,prevent){
		  		  
		  if(prevent)e.preventDefault();
			  
		  var arr = this.state.showSubmenu.map(function(item,index){
			  if(index !== menu)return {...item,show:false};
			  else return {...item,show:!item.show};
		  });
		  
		  if(prevent)this.setState({...this.state,showSubmenu:arr});
		  else this.setState({...this.state,showSubmenu:arr,cl:''});
	  }
	  
	  handleClickOutside = evt => {
		  this.setState(INITIAL_STATE);
	  };
	
	  render() {

		const {cl} = this.state;
		const authUser = localStorage.getItem('loggedUser');

		const adminRules = this.authLib.GetObjFromRules(this.context.rules);
						  
	    return (
	    	<div id="header" className="mb-1">
	    		<ul className="d-flex m-0 p-0 main">
	    		    <li><button tabIndex="0" onClick={this.showSideBar} ><img src={menu} /></button></li>
	    		    <li className="logo"><img alt="astralnicestovani" src={logo} /> MŮJ TERAPEUT</li>
	    			<li className="user ml-auto">
						<NavLink to="/settings/change-password">
							{authUser}
						</NavLink></li>
	    			<li className="">
						<ApolloConsumer>
							{client => (
								<button onClick={(e) => this.logout(e,client)}><img src={door} /></button>
							)}
								
						</ApolloConsumer>
					</li>
	    		</ul>
	    		<div className={"sideBar " + cl}>
	    			<ul>
						{adminRules.showOrders ?
							<li>
								<NavLink onClick={(e) => this.toogleSubmenu(e,4,true)} activeClassName="active" to="/video"><img src={basket} /> <span>Objednávky</span></NavLink>
								<ul className={(this.state.showSubmenu[4].show) ? '' : 'hidden' }>
									{adminRules.showInvoices ?
										<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/order/invoice"> Faktury</NavLink></li> 
									:null}
									{adminRules.showCreditNotes ?
										<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/order/credit-note"> Dobropisy</NavLink></li> 
									:null}
								</ul>
							</li>
						:null}

						{adminRules.showCustomers ?
	    					<li><NavLink onClick={(e) => this.toogleSubmenu(e,0)} activeClassName="active" to="/customer"><img src={user} /> <span>Zákaznící</span></NavLink></li>
	    				:null}

						{adminRules.showContent ?
							<li>
								<NavLink onClick={(e) => this.toogleSubmenu(e,2,true)} strict to="/content"><img src={content} /> <span>Obsah</span></NavLink>
								<ul className={(this.state.showSubmenu[2].show) ? '' : 'hidden' }>
									{adminRules.showLinks ?
										<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/link"> Seznam odkazů</NavLink></li> 
									:null}
									{adminRules.showArticles ?
										<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/article"> Seznam článků</NavLink></li> 
									:null}
									{adminRules.showReviews ?
										<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/review"> Seznam recenzí</NavLink></li> 
									:null}
									{adminRules.showCategories ?
										<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/categories"> Kategorie</NavLink></li> 
									:null}
									{adminRules.showVideos ?
										<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/videos"> Videa</NavLink></li>  
									:null}
									{adminRules.showServices ?
										<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/services"> Služby</NavLink></li> 
									:null}
									{adminRules.showMarketing ?
										<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/marketing"> Marketing</NavLink></li> 
									:null}
									{adminRules.showForms ?
										<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/forms"> Formuláře</NavLink></li> 
									:null}
									{adminRules.showNewsletter ?
										<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/newsletter"> Newsletter</NavLink></li>
									:null}
									{adminRules.showCampaigns ?
										<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/campaign"> Kampaně</NavLink></li>
									:null}
									{adminRules.showMembership ?
										<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/membership"> Členství / produkty</NavLink></li>
									:null}
								</ul>
							</li>
						:null}

						{adminRules.showReservations ?
							<li><NavLink onClick={(e) => this.toogleSubmenu(e,0)} activeClassName="active" to="/reservations"><img src={calendar} /> <span>Rezervace</span></NavLink></li>
	        			:null}
						<li>
	        				<NavLink onClick={(e) => this.toogleSubmenu(e,3,true)} activeClassName="active" to="/settings"><img src={settings} /> <span>Nastavení</span></NavLink>
	        				<ul className={(this.state.showSubmenu[3].show) ? '' : 'hidden' }>
	        				    <li className="last"><NavLink onClick={this.handleClickOutside} activeClassName="active" to="/settings/change-password"> Změna hesla </NavLink></li>
	        				</ul>
	        			</li>
	        			<li>
						    <ApolloConsumer>
								{client => (
									<button onClick={(e) => this.logout(e,client)}><img src={doorLighter} /> <span>Odhlásit se</span></button>
								)}
								
							</ApolloConsumer>
						</li>

	    			</ul>
	    		</div>
	    	</div>
	    );
	  }
  
}


export default withRouter(onClickOutside(Header));
