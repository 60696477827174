/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component, Fragment } from 'react';
import AddEditArticle from './AddEditArticle';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import { withApollo } from 'react-apollo';
import {GET_ARTICLES,DELETE_ARTICLES} from '../Queries/article';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import edit from '../../../Public/Images/edit.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import ArticleLib from '../Library/article';
import Helper from '../../../Library/helper';
import {ARTICLE_IMAGE_URL,SERVER_URL} from '../../../Config/index';
import Auth from '../../Auth/Library/auth.js';
import { AuthContext } from '../../Auth/Components/AuthProvider.js';

const INITIAL_STATE = {
    showAdd : false,
    showDeleteNotifi:false,
    deleteArticle:"",
    selectedArticles:[],
    domain:"https://www.mujterapeut.cz",
    listVariables:{
        lang:"cz",
        limit:30,
        offset:0,
    }
}

class AllArticles extends Component{

    static contextType = AuthContext;

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;
       this.openCloseModal  = this.openCloseModal.bind(this);
       this.deleteArticle   = this.deleteArticle.bind(this);
       this.delArticle      = this.delArticle.bind(this);
       this.showDelNotifi   = this.showDelNotifi.bind(this);
       this.setLang         = this.setLang.bind(this);

       this.authLib = new Auth();

    }

    openCloseModal(type,articleID){
        this.setState({
            showAdd:type,
            selectedArticleID:articleID
        })
    }

    /**
     * smazání štítků
     * @param {*} deleteArticles funkce, která posílá data na server
     * @param {*} articleID ID articleu
     */
    
    deleteArticle(e,deleteArticles,articleID){

        var checked = e.target.checked;
        var arr = [...this.state.selectedArticles];

        if(!checked){
            for(let i in this.state.selectedArticles){
                if(this.state.selectedArticles[i] == articleID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [articleID,...arr];
        }

        
        this.setState({
            deleteArticles,
            selectedArticles:arr
        });
  
    }

    delArticle(action){

        if(action){

            let articleIDs = this.state.selectedArticles.join(",");

            this.state.deleteArticles({
                variables:{
                    articleIDs: articleIDs
                }
            })
        }
        this.setState(INITIAL_STATE);
    }

    showDelNotifi(){

        if(this.state.selectedArticles.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste article.',false,true,this.props.client);
        }
    }

    setLang(e){

        var data = e.target.value;
        data = data.split("-");

        this.setState({domain:data[2] + "://www." + data[1],listVariables:{...this.state.listVariables,lang:data[0]}})
    }

    render(){

        const {selectedArticles,listVariables,domain} = this.state;

        const adminRules = this.authLib.GetObjFromRules(this.context.rules);
        if(!adminRules.showArticles)
            window.location.href="/";

        return(

            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        <Query query={GET_ARTICLES} variables={listVariables}>
                            {
                                ({data,loading,error,fetchMore }) => {
                                    
                                    if(error) return (<Error text={error} />);

                                    return(
                                    <div className="card main">
                                        
                                        <div className="card-header d-flex align-items-center">
                                            Seznam článků
                                            <div className="ml-auto">
                                                <select style={{"padding":"0px 15px","height":"30px"}} className="form-control" name="selectedLang" onChange={(e) => this.setLang(e)}>
                                                    {data && data.allDomains && data.allDomains.map((item,index) => (
                                                        <option key={index} value={item.lang + "-" + item.domainName + "-" + item.protocol}> {item.lang} </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <button className="btn btn-primary btn-thiner ml-2" onClick={() => this.openCloseModal(true)}>Přidat</button>
                                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => this.showDelNotifi()}>Smazat označené</button>
                                        </div>
                                        <div className="card-body">
                                            
                                            {loading ?
                                                <Loading />
                                                
                                                :
                                            
                                                <div>
                                                    <div className="data-list">
                                                        
                                                        <div className="data-list-item header">
                                                            <div className="data-list-item-content">
                                                                <div>Obrázek</div>
                                                                <div>Nadpis</div>
                                                                <div>Publikováno</div>
                                                                <div>Odkaz</div>
                                                                <div className="text-right">Možnosti</div>
                                                            </div>
                                                        </div>
                                                        <Mutation
                                                            mutation = {DELETE_ARTICLES}
                                                            update = {async (cache, response) => {

                                                                let article = new ArticleLib(this);
                                                                article.updateAfterDelete(cache, response,listVariables);

                                                                let notify = new Notification();
                                                                notify.setNotification(cache,'Úspěšně smazáno',true,false);
                                            
                                                            }}
                                                            onError = {(error) => {
                                                                const helper = new Helper(); 
                                                                let notify = new Notification();
                                                                notify.setNotification(null,helper.getApolloErrorText(error),false,true,this.props.client);
                                                            }}
                                                        >
                                                            {
                                                                (deleteArticle,response) => {
                                                            
                                                                    return(
                                                                        <Fragment>
                                                                            {data.allArticles && data.allArticles.map((item,index) => {

                                                                                var checked = false;
                                                                                for(let i in selectedArticles){
                                                                                    if(selectedArticles[i] == item.articleID)checked = true;
                                                                                }
                                                                                                                                                
                                                                                return (
                                                                                
                                                                                    <div key={index} className="data-list-item-content">
                                                                                        <div><a target="_blank" href={domain + "/blog/" + item.articleID + "-" + item.niceTitle}><img src={SERVER_URL + "/" + ARTICLE_IMAGE_URL + "/mala_" + item.photo} /></a></div>
                                                                                        <div>{item.title}</div>
                                                                                        <div>
                                                                                            {item.langPublished && item.langPublished.map((item,index) => (
                                                                                                <div style={{textTransform:"uppercase"}} key={index}><span>{item.lang}</span> : <span className={( item.published == 0 ? "badge badge-danger" : "badge badge-success")} >{( item.published == 0 ? "NE" : "ANO")}</span></div>
                                                                                            ))}
                                                                                        </div>
                                                                                        <div><a target="_blank" href={domain + "/blog/" + item.articleID + "-" + item.niceTitle}>{domain + "/blog/" + item.articleID + "-" + item.niceTitle}</a></div>
                                                                                        <div className="text-right">
                                                                                            <img onClick={() => this.openCloseModal(true,item.articleID)} className="edit-icon" src={edit} /> 
                                                                                            <input className="delete-checked" type="checkbox" name="deleteArticle[]" checked={checked} onChange = {(e) => this.deleteArticle(e,deleteArticle,item.articleID)} />
                                                                                        </div>
                                                                                    </div>
                                                                                            
                                                                                )
                                                                                
                                                                            })} 
                                                                        </Fragment>
                                                                    )
                                                                }
                                                            }
                                                        </Mutation>
                                                    </div>

                                                    {data.allArticles && data.allArticles.length == 0 ?
                                                        <NoItems text={"Momentálně se zde nenachází žádné články."} />
                                                    :
                                                        <div className="text-center p-5">
                                                            <button className="btn btn-primary" onClick={() => fetchMore({
                                                                variables: {
                                                                    offset: data.allArticles.length
                                                                },
                                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                                    if (!fetchMoreResult) return prev;
                                                                    return Object.assign({}, prev, {
                                                                        allArticles: [...prev.allArticles, ...fetchMoreResult.allArticles]
                                                                    });
                                                                }
                                                            })}> Načíst další články </button>
                                                        </div> 

                                                    }
                                                
                                                </div>

                                            }
                                                    
                                        </div>
                                    </div>
                                );
                            }
                        }
                        </Query>
                    </div>
                </div> 	
                {this.state.showAdd &&
                    <AddEditArticle listVariables={listVariables} openCloseModal={this.openCloseModal} selectedArticleID={this.state.selectedArticleID} />
                }	
                {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané články ?'} callback={this.delArticle} /> }	
            </div>
        );

    }

}

export default withApollo(AllArticles);