/*
    Přidání kategorie
*/
import React,{ Component,Fragment } from 'react';
import {Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_MEMBERSHIP} from '../Queries/index.js';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import MembershipLib from '../Library/membership';
import TinyMCEBasic from '../../TinyMCEFileManager/Components/TinyMCEBasic';

const INITIAL_STATE = {
    err:"",
    formData:{
        membershipDurationID:0,
        thanksLinkID:0,
        langs:[],
        selectedLang:'cz',
    },
    allLinks:[],
    allLanguageMutations:[]
}

class AddEditMembership extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.membershipLib = new MembershipLib(this); 
    }

    componentDidMount(){
        this.membershipLib.getData();
    }    

    render(){

        var {err}               = this.state;
        var {selectedMembershipID,listVariables} = this.props;

        return(

            <Mutation 
                mutation={ADD_EDIT_MEMBERSHIP}
                errorPolicy = "all"
                update = {async (cache, response) => {

                    this.membershipLib.updateList(cache,response,selectedMembershipID,listVariables);

                    let notify = new Notification();

                    if(selectedMembershipID && selectedMembershipID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    else notify.setNotification(cache,'Úspěšně přidáno',true,false);

                }}

                onCompleted = {() => {
                    this.props.openCloseModal(null);
                }}
                
            >
            {
                (addEditMembership,{loading, error}) => {

                    const {selectedLang,membershipDurationID,thanksLinkID} = this.state.formData;
                    const {allLanguageMutations,allLinks} = this.state;
                    
                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(null)}
                        >
                            <div className="modal-content">
                                <div className="modal-header d-flex align-items-center">
                                    <h4 className="modal-title">{(selectedMembershipID && selectedMembershipID != 0 ? "Úprava členství / produktu" : "Přidání členství / produktu")}</h4>
                                    <div className="ml-auto">
                                        <select className="form-control" name="selectedLang" onChange={(e) => this.membershipLib.formLangObjectHandle(e)}>
                                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                <option key={index} value={item.languageID}> {item.suffix} </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(null)}>                                    
                                        <span aria-hidden="true">x</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {err ?
                                        <Error text={err} />
                                    :
                                        <Fragment>
                                            {!loading ?
                                            <div className="row">  

                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="input-label">Děkovací stránka</label>
                                                        <select className="form-control" name="thanksLinkID" value={thanksLinkID} onChange={(e) => this.membershipLib.formHandle(e)}>
                                                            <option value={0} key={0}>--Nepoužívat děkovací stránku--</option>
                                                            {allLinks.map((item,index) => {
                                                                return(
                                                                    <option value={item.linkID} key={item.linkID}>{item.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-12">
                                                    <label className="input-label">Text emailu ({selectedLang})</label>
                                                    <div className="form-group">
                                                        <TinyMCEBasic 
                                                            name="emailText"
                                                            value={this.membershipLib.getLangValue("emailText")}
                                                            OnEditorChange={(content) => this.membershipLib.formLangHandleEditor(content,"emailText")}
                                                        />
                                                        
                                                    </div>
                                                </div>

                                                                                                
                                            </div>

                                            :

                                            <Loading />  

                                            }  
                                        </Fragment>
                                    }
                                            
                                </div>
                                <div className="modal-footer"> 
                                    {!loading && <button type="button" className="btn btn-primary flex-fill" onClick={() => this.membershipLib.addMembership(addEditMembership)}>{(selectedMembershipID && selectedMembershipID != 0 ? "Upravit" : "Přidat")}</button>}
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(null)}>Zavřít</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditMembership);