import Calendar from "./Calendar";
import { GET_RESERVATION_CALENDAR_DATA,GET_RESERVATION_DATA } from "../Queries/reservations";
import { useApolloClient, useQuery } from "react-apollo";
import UseBlockDay from '../Hooks/UseBlockDay';
import UseRemoveBlockedDay from "../Hooks/UseRemoveBlockedDay";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import { useState,useContext } from "react";
import Loading from "../../../GlobalComponents/Loading";
import Helper from "../../../Library/helper";
import Error from "../../../GlobalComponents/Error";
import Auth from '../../Auth/Library/auth.js';
import { AuthContext } from '../../Auth/Components/AuthProvider.js';

const Reservations = () => {

    const helper = new Helper();

    const contextData =  useContext(AuthContext);

    const authLib = new Auth();
    const adminRules = authLib.GetObjFromRules(contextData.rules);
    if(!adminRules.showReservations)
        window.location.href="/";

    const client = useApolloClient();
    const [variables,SetVariables] = useState({month:-1,year:0});
    const [calendarKey,SetCalendarKey] = useState(0);
    const [selDate,SetSelDate] = useState(null);
    const [reservationPersonID,SetReservationPersonID] = useState(null);

    const {showNotify,BlockDay,ShowNotification,BlockDayWithoutNotify} = UseBlockDay(client,reservationPersonID,() => SetCalendarKey((prevValue) => prevValue + 1));
    const {showRemoveNotify,ShowRemoveNotification,RemoveBlockDay} = UseRemoveBlockedDay(client,reservationPersonID,() => SetCalendarKey((prevValue) => prevValue + 1));

    const {data,error,loading} = useQuery(GET_RESERVATION_DATA,{
        onCompleted:(d) => {
            SetReservationPersonID(d.allReservationPersons[0].reservationPersonID);
        }
    });

    const SetReservationPerson = (id) => {

        SetReservationPersonID(id);
        SetCalendarKey((prevValue) => prevValue + 1);
    }

    const GetReservationCalendarData = (month,year,reservationPersonID) => {

        return new Promise(async (resolve,response) => {

            SetVariables({...variables, month:month,year:year});

            try{
                var data = await client.query({
                    query:GET_RESERVATION_CALENDAR_DATA,
                    variables:{
                        month,
                        year,
                        reservationPersonID
                    },
                    fetchPolicy: 'network-only'
                    
                }) 

                var blockedDays = [];
                if(data.data.monthReservations)
                {
                    for(let val of data.data.monthReservations)
                    {
                        var d = val.date.split(" ");
                        blockedDays.push({date:d[0],customer:val.customer});
                    }
                }

                if(data.data.monthBlockedDays)
                {
                    for(let val of data.data.monthBlockedDays)
                    {
                        blockedDays.push({date:val.date});
                    }
                }

                resolve(blockedDays);
            }
            catch(err)
            {
                response(err);  
            }
        })
    }

    return(
        <div id="settings" className="whole-container" >	
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">

                        <div className="card-header d-flex align-items-center">
                            Kalendář rezervací
                        </div>
                        {error ?
                            <Error text = {helper.getApolloErrorText(error)} />
                        :
                            (reservationPersonID && !loading ?
                                <div style={{maxWidth:"40rem",margin:"0 auto"}}>
                                    
                                    <div className="d-flex justify-content-center flex-column" style={{padding:"4rem"}}>

                                        <div className="form-group">
                                            <select 
                                                className="form-control"
                                                name = "reservationPersonID"
                                                value = {reservationPersonID}
                                                onChange = {(e) => SetReservationPerson(e.target.value)}
                                            >
                                                {data.allReservationPersons.map((item,index) => (
                                                    <option key={item.reservationPersonID} value={item.reservationPersonID}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <Calendar
                                            key={calendarKey}
                                            showLegend = {true} 
                                            showPast   = {true}
                                            selectedDate = {selDate}
                                            OnClickOnBlockedDay = {(date,dateStr,customer) => {ShowRemoveNotification(dateStr,customer);SetSelDate(date)}}
                                            OnSelectDate = {(date,dateDb) => {BlockDayWithoutNotify(dateDb);SetSelDate(date)}}
                                            GetBlockedDays = {(month,year) => GetReservationCalendarData(month,year,reservationPersonID)}
                                        />
                                    </div>
                                </div>
                            :
                                <Loading />
                            )
                        }

                        {showNotify ?
                            <ModalNotification 
                                yesNo={true} 
                                text={'Opravdu chcete zablokovat toto datum ?'} 
                                callback={BlockDay} 
                            />
                        :null}

                        {showRemoveNotify ?
                            <ModalNotification 
                                yesNo={true} 
                                text={'Opravdu chcete odstranit tuto blokaci ?'} 
                                callback={RemoveBlockDay} 
                            />
                        :null}
                        
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Reservations;