import React,{ Component,Fragment,useEffect,useState } from 'react';
import DragColumnElement from './DragColumnElement';
import remove from '../../../Public/Images/remove.svg';
import parent from '../../../Public/Images/parent.svg';
import settings from '../../../Public/Images/settings.svg';
import move from '../../../Public/Images/move.svg';

const DragColumnsElement = ({
    handle,
    element,
    lastIndexes,
    cm,
    showHelpers,
    iframeNode,
    fullWidth,
    lang, 
    allServices, 
    allForms,
    allReservationPersons
}) => {

    const getColumnClass = (type,value) => {

        var className = "col"+type;
        switch(value){
            case "100": className += "-12";break;
            case "75": className += "-9";break;
            case "66": className += "-8";break;
            case "60": className += "-7";break;
            case "50": className += "-6";break;
            case "40": className += "-5";break;
            case "33": className += "-4";break;
            case "25": className += "-3";break;
            case "16": className += "-2";break;
        }

        return className;

    }

    var parentIndexes = "";
    
    var indexes = lastIndexes.split("-");
    if(indexes.length > 2){
        indexes.pop();
        parentIndexes = indexes.join("-");
    }

    return (
        <div className="cm-columns row">

            {showHelpers && element.showMenu &&
                <div className="cm-menu cm-col" onClick={(e) => e.stopPropagation()}>
                    <div className="form-inline">
                        <div className="form-group mr-1">
                            <button onClick={() => cm.addDragColumn(lastIndexes)}>Přidat sloupec</button>
                        </div>
                                                    
                        <div className="form-group mr-1">
                            <div className="item-container" onClick={() => cm.showSettings("drag_columns",lastIndexes)}>
                                <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                            </div>
                            {element.showSettings &&
                                <Fragment>
                                    <div className="settings">
                                                                                    
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Třída:</label>
                                                </div>
                                                <input type="text" value={element.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes,"drag_columns")}  />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cm-closing-overlay" onClick={() => cm.showSettings("drag_columns",lastIndexes)}></div>
                                </Fragment>
                            }
                        </div>
                        {parentIndexes != "" &&
                            <div className="form-group mr-1">
                                <div className="item-container" onClick={(e) => cm.selectElement(e,"drag_column",parentIndexes)}>
                                    <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                </div>
                            </div>
                        }
                        <div className="form-group">
                            <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                <img className="remove-icon" src={remove} alt="Odstranit sloupce" title="Odstranit sloupce" />
                            </div>
                        </div>
                        
                    </div>
                </div>
            }

            <div className={(fullWidth == 1 ? "container-fluid"  : "container")}>

                
                <div className={"row " + element.className} >
    
                    {element.cols.length > 0 && element.cols.map((item,index) => {

                        var colClasses = "";
                        if(item.xlCols)colClasses += getColumnClass("-xl", item.xlCols) + " ";
                        if(item.lgCols)colClasses += getColumnClass("-lg", item.lgCols) + " ";
                        if(item.mdCols)colClasses += getColumnClass("-md", item.mdCols) + " ";
                        if(item.smCols)colClasses += getColumnClass("-sm", item.smCols) + " ";
                        if(item.xsCols)colClasses += getColumnClass("", item.xsCols) + " ";

                        return(
                            <div key={index} className={"col cm-column " + colClasses + " "}>
                               
                                <DragColumnElement colsLength = {element.cols.length} allReservationPersons = {allReservationPersons} allServices = {allServices} allForms = {allForms} item={item} index={index} lastIndexes={lastIndexes} cm = {cm} showHelpers = {showHelpers} iframeNode = {iframeNode} fullWidth = {fullWidth} lang = {lang} />
                                    
                            </div>
                        )
                    })}

                    {showHelpers &&<div className={"cm-col-border" + (element.showMenu ? " cm-selected" : "")}></div>}
                    {showHelpers && 
                        <div className="cm-dragable-item" {...handle}>
                            <div className="item-container">
                                <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                            </div>
                        </div>
                    }
                </div>
                        
            </div>
            
        </div>
    );

    
}

export default DragColumnsElement;