/**
 *  Pomocná třída s obecnými funkcemi
 */

class Helper{

    getApolloErrorText(error){

        var newError = JSON.stringify(error);
        newError = JSON.parse(newError);
        
        if(newError.graphQLErrors && newError.graphQLErrors[0])
            return newError.graphQLErrors[0].message;
        if(newError.networkError && newError.networkError.result.errors)
            return newError.networkError.result.errors[0].message;
        else
            return error.message;

    }

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    OpenImage(e,CallBack){
        var photo = e.target.files[0];        
        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;
            CallBack(photo,dataURL);
        };
        reader.readAsDataURL(photo);
    }

    CompleteLangsArray(langsFromDb,allActiveLanguages,emptyData)
    {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet)
            {
                var dataToPush = {...emptyData};
                dataToPush.lang = l.suffix;
                langs.push(dataToPush);
            }
        }
        
        return langs;
    }

}

export default Helper;